var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground,"short-title":"","title":""}},[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Dashboard',
          icon: 'ni ni-chart-bar-32 text-green',
          path: '/dashboard',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Prospects',
          icon: 'fa fa-users text-primary',
          path: '/prospects',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'COI Requests',
          icon: 'ni ni-bullet-list-67 text-red',
          path: '/coi-requests',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Increase Requests',
          icon: 'ni ni-bullet-list-67 text-yellow',
          path: '/increase-requests',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Start Claim',
          icon: 'ni ni-bullet-list-67 text-gray',
          path: '/start-claim',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Clients',
          icon: 'fa fa-users text-primary',
          path: '/clients',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Sign Ins',
          icon: 'ni ni-key-25 text-purple',
          path: '/sign-ins',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Admins',
          icon: 'ni ni-single-02 text-purple',
          path: '/admins',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Archive',
          icon: 'ni ni-archive-2 text-gray-dark',
          path: '/archive',
        }}})],1)],2),_c('div',{staticClass:"main-content",attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar'),_c('div',{on:{"click":_vm.toggleSidebar}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }