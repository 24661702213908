<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar :background-color="sidebarBackground" short-title="" title="">
      <template slot="links">
        <sidebar-item
          :link="{
            name: 'Dashboard',
            icon: 'ni ni-chart-bar-32 text-green',
            path: '/dashboard',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Prospects',
            icon: 'fa fa-users text-primary',
            path: '/prospects',
          }"
        />
        <sidebar-item
          :link="{
            name: 'COI Requests',
            icon: 'ni ni-bullet-list-67 text-red',
            path: '/coi-requests',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Increase Requests',
            icon: 'ni ni-bullet-list-67 text-yellow',
            path: '/increase-requests',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Start Claim',
            icon: 'ni ni-bullet-list-67 text-gray',
            path: '/start-claim',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Clients',
            icon: 'fa fa-users text-primary',
            path: '/clients',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Sign Ins',
            icon: 'ni ni-key-25 text-purple',
            path: '/sign-ins',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Admins',
            icon: 'ni ni-single-02 text-purple',
            path: '/admins',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Archive',
            icon: 'ni ni-archive-2 text-gray-dark',
            path: '/archive',
          }"
        />
      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardNavbar from "./DashboardNavbar.vue";
import { FadeTransition } from "vue2-transitions";

export default {
  components: {
    DashboardNavbar,
    FadeTransition,
  },
  data() {
    return {
      sidebarBackground: "vue", //vue|blue|orange|green|red|primary
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
<style lang="scss"></style>
