<template>
  <base-nav
    class="navbar-top navbar-dark"
    id="navbar-main"
    :show-toggle-button="false"
    expand
  >
    <ul class="navbar-nav align-items-center d-none ml-auto d-md-flex">
      <li class="nav-item dropdown ">
        <base-dropdown class="nav-link pr-0 cursor-pointer">
          <div class="media align-items-center" slot="title">
            <div class="media-body mr-5 d-none d-lg-block">
              <span class="mb-0 text-sm font-weight-bold user-dropdown">{{
                user.email
              }}</span>
            </div>
          </div>
          <div @click.prevent="signOut">
            <router-link to="/dashboard" class="dropdown-item">
              <i class="ni ni-user-run" @click.prevent="signOut"></i>
              <span @click.prevent="signOut">Logout</span>
            </router-link>
          </div>
        </base-dropdown>
      </li>
    </ul>
  </base-nav>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchQuery: "",
    };
  },
  methods: {
    ...mapActions(["logOut"]),

    async signOut() {
      let resp = await this.logOut();
      if (resp.logOut === true) {
        await this.$router.push("/");
      }
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
  },
  watch: {
    loggedIn() {
      if (!this.loggedIn) {
        this.$router.push("/");
      }
    },
  },
  computed: mapGetters(["user", "loggedIn"]),
};
</script>
<style scoped lang="scss">
.user-dropdown {
  color: #ffd460;

  &:hover {
    color: darken(#ffd460, 20%);
    transition: color ease 0.2s;
  }
}
</style>
